import React from "react";

const SmsLogin = ({ userName, errorMessage, onChange, onSubmit }) => (
    <form onSubmit={onSubmit}>
        <div className="form-group">
            <span className="phone_sign">+47</span>
            <input
                type="text"
                className="form-control"
                placeholder="Mobile*"
                value={userName}
                onChange={onChange}
            />
            {errorMessage?.mobile && (
                <p className="text-danger text-center text-small">{errorMessage.mobile}</p>
            )}
        </div>
        <div className="text-center pt-3">
            <button type="submit" className="btn btn-primary login-btn">Login</button>
        </div>
    </form>
);

export default SmsLogin;