import React from "react";

const EmailLogin = ({ userName, emailOption, errorMessage, onChange, onValueChange }) => (
        <>
        <div className="form-group">
            <input
                type="text"
                className="form-control email_field"
                placeholder="example@email.com*"
                value={userName}
                onChange={onChange}
            />
            {errorMessage?.email && (
                <p className="text-danger text-center text-small">{errorMessage.email}</p>
            )}
        </div>

        <div className="form-group radio_btn">
            <label>
                <input
                    type="radio"
                    name="email_method"
                    value="url"
                    className="input-radio off"
                    checked={emailOption === "url"}
                    onChange={onValueChange}
                /> Redirect URL
            </label>
            <label>
                <input
                    type="radio"
                    name="email_method"
                    value="code"
                    className="input-radio on"
                    checked={emailOption === "code"}
                    onChange={onValueChange}
                /> Code
            </label>
        </div>        
    </>
);

export default EmailLogin;
